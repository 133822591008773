import React, { FC } from "react";
import Icon, { IconProps } from "./Icon";

const ChevronLeft: FC<IconProps> = (props) => (
  <Icon {...props} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.522684 2.63588C-0.104593 2.03323 -0.104594 1.02976 0.522684 0.427104C1.11523 -0.142183 2.05145 -0.142394 2.64426 0.426626L11.1159 8.55833C11.9356 9.34522 11.9359 10.6562 11.1165 11.4434L2.59197 19.6333C1.99935 20.2027 1.06297 20.2027 0.470356 19.6333C-0.156785 19.0308 -0.156785 18.0276 0.470356 17.425L8.19323 10.0053L0.522684 2.63588Z"
      transform="translate(6.38165 1.96946)"
    />
  </Icon>
);

export default ChevronLeft;
