import { useTranslation } from "react-i18next";
import { trackEvents } from "../../utils/tracking/tracking";
import { gaActions } from "../../utils/constants";
import { useEffect } from "react";

import "./styles.scss";

const AppHeader = function () {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    trackEvents({
      action: gaActions.display,
      label: "configurator",
      nuEventName: `${gaActions.display}_configurator`,
    });
  });

  const handleGAEvent = function () {
    trackEvents({
      action: gaActions.clickHeader,
      label: "contact_support",
      nonInteraction: false,
      nuEventName: `${gaActions.clickHeader}_contact_support`,
    });
  };

  return (
    <div className="AppHeader">
      <div className="logo">
        <img src="/assets/schneider_life_green_logo.svg" alt="Schneider logo" />
      </div>
      <div className="right">
        <select
          style={{ display: "none" }}
          onChange={(e) => i18n.changeLanguage(e.target.value)}
        >
          <option value="en">English</option>
          <option value="fr">Français</option>
        </select>
        <div className="help">
          <a
            onClick={handleGAEvent}
            target="_blank"
            rel="noreferrer"
            href="https://www.se.com/fr/fr/work/support/customer-care/contact-schneider-electric.jsp"
          >
            {t("header.contactSupport")}
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://download.schneider-electric.com/files?p_Doc_Ref=Wiser_SUG_FR"
          >
            {t("header.installationGuide")}
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://download.schneider-electric.com/files?p_Doc_Ref=ZZ6792"
          >
            {t("header.faq")}
          </a>
        </div>
      </div>
    </div>
  );
};

export default AppHeader;
