import "./styles.scss";

interface CustomLayoutRoomItemProps {
  label: string;
  count: number;
}

const CustomLayoutRoomItem = ({ label, count }: CustomLayoutRoomItemProps) => {
  return (
    <div className="CustomLayoutSelection_Room">
      <div className="CustomLayoutSelection_Room_Label">{label}</div>
      <div className="CustomLayoutSelection_Room_Count">{`x ${count}`}</div>
    </div>
  );
};

export default CustomLayoutRoomItem;
