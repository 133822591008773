import { ChangeEvent, FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { gaActions } from "../../utils/constants";
import { trackEvents } from "../../utils/tracking/tracking";
import "./styles.scss";
import StepContainer from "../StepContainer";
import Card from "../Card";
import PropertyLayoutStep from "./components/PropertyLayoutStep";
import { DeviceTypes } from "../../shared/types";
import { RoomListDefinition } from "./components/PropertyLayoutStep/types";

const PropertyTypes = {
  home: "home",
  apartment: "apartment",
};

enum PropertType {
  home = "home",
  apartment = "apartment",
}

interface HomeContainerProps {
  step: number;
  propertyType: PropertType;
  layoutType: RoomListDefinition;
  deviceTypes: DeviceTypes[];
  homeContainerStep: number;
  setNextStep: () => void;
  setPreviousStep: () => void;
  setDeviceTypes: (deviceTypes: DeviceTypes[]) => void;
  setPropertType: (propertyType: PropertType) => void;
  setLayoutType: (layoutType: RoomListDefinition) => void;
  setHomeContainerStep: (step: number) => void;
  customLayoutSelected: boolean;
  setCustomLayoutSelected: (isOpen: Boolean) => void;
}

const HomeContainer: FC<HomeContainerProps> = ({
  customLayoutSelected,
  homeContainerStep,
  deviceTypes,
  propertyType,
  layoutType,
  setNextStep,
  setDeviceTypes,
  setPropertType,
  setHomeContainerStep,
  setLayoutType,
  setCustomLayoutSelected,
}) => {
  const [t] = useTranslation();
  const [currentStep, setCurrentStep] = useState(0);

  const handlePropertyTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    trackEvents({
      action: gaActions.completePropertyLayoutStep,
      nonInteraction: false,
      nuEventName: gaActions.completePropertyLayoutStep,
    });
    setCurrentStep(currentStep + 1);
    setHomeContainerStep(homeContainerStep + 1);
    setPropertType(e.target.value as PropertType);
  };

  useEffect(() => {
    let eventAction = "";

    switch (homeContainerStep) {
      case 0:
        eventAction = "Property_Type";
        break;
      case 1:
        eventAction = "Property_Layout";
        break;
      case 2:
        eventAction = "Device_type";
        break;

      default:
        break;
    }

    trackEvents({
      action: `${gaActions.display}_${eventAction}`,
      label: "step_display",
      nuEventName: `${gaActions.display}_${eventAction}_step_display`,
    });
  }, [homeContainerStep]);

  const toggleDeviceType = (key: DeviceTypes) => {
    return () => {
      const index = deviceTypes.indexOf(key);
      if (index >= 0) {
        deviceTypes.splice(index, 1);
      } else {
        deviceTypes.push(key);
        trackEvents({
          action: gaActions.clickDeviceType,
          label: key,
          nonInteraction: false,
          nuEventName: `${gaActions.clickDeviceType}_${key}`,
        });
      }

      // setSelectedDeviceTypes([...selectedDeviceTypes]);
      setDeviceTypes([...deviceTypes]);
    };
  };

  const handleNext = () => {
    return () => {
      if (homeContainerStep === 2) {
        trackEvents({
          action: gaActions.clickNavigation,
          label: "next",
          nonInteraction: false,
          nuEventName: `${gaActions.clickNavigation}_next`,
        });
        setNextStep();
        return;
      }

      trackEvents({
        action: gaActions.clickNavigation,
        label: "next",
        nonInteraction: false,
        nuEventName: `${gaActions.clickNavigation}_next`,
      });
      setHomeContainerStep(homeContainerStep + 1);
    };
  };

  const handleBack = () => {
    if (!homeContainerStep) {
      return undefined;
    }
    return () => {
      trackEvents({
        action: gaActions.clickNavigation,
        label: "back",
        nonInteraction: false,
        nuEventName: `${gaActions.clickNavigation}_back`,
      });
      setCurrentStep(currentStep - 1);
      setHomeContainerStep(homeContainerStep - 1);
    };
  };

  const canProgress =
    (homeContainerStep === 0 && !!propertyType) ||
    (homeContainerStep === 1 && true) ||
    (homeContainerStep === 2 && !!deviceTypes.length);

  const confirmLayoutSelection = (roomState: RoomListDefinition) => {
    trackEvents({
      action: gaActions.clickNavigationPropertyLayout,
      label: "step_complete",
      nonInteraction: false,
      nuEventName: `${gaActions.clickNavigationPropertyLayout}_step_complete`,
    });
    setLayoutType(roomState);
    setCurrentStep(currentStep + 1);
    setHomeContainerStep(homeContainerStep + 1);
  };

  return (
    <StepContainer
      canProgress={canProgress}
      onBack={handleBack()}
      onNext={handleNext()}
    >
      <div className="HomeContainer">
        <Card
          disabled={homeContainerStep !== 0}
          title={t("homeContainer.choosePropertyType")}
        >
          <div className="property-type">
            <div className="card-radio-container">
              <label
                className={`card-radio-container-item home ${
                  propertyType === PropertyTypes.home ? "checked" : ""
                }`}
              >
                <img alt="home" src={"/assets/home.png"} />
                <span>{t("homeContainer.propertyTypeHome")}</span>
                <input
                  onChange={handlePropertyTypeChange}
                  type="radio"
                  name="propertyType"
                  value="home"
                />
              </label>
              <label
                className={`card-radio-container-item apartment  ${
                  propertyType === PropertyTypes.apartment ? "checked" : ""
                }`}
              >
                <img alt="apartment" src={"/assets/apartment.png"} />
                <span>{t("homeContainer.propertyTypeApartment")}</span>
                <input
                  onChange={handlePropertyTypeChange}
                  type="radio"
                  name="propertyType"
                  value="apartment"
                />
              </label>
            </div>
          </div>
        </Card>
        <PropertyLayoutStep
          disabled={homeContainerStep !== 1}
          onSelect={confirmLayoutSelection}
          layoutType={layoutType}
          customLayoutSelected={customLayoutSelected}
          setCustomLayoutSelected={setCustomLayoutSelected}
        />
        <Card
          disabled={homeContainerStep !== 2}
          title={t("homeContainer.chooseDeviceType")}
        >
          <div className="card-checkbox-container">
            <div
              onClick={toggleDeviceType(DeviceTypes.LIGHTNING)}
              className={`card-checkbox-container-item lightning ${
                deviceTypes.indexOf(DeviceTypes.LIGHTNING) >= 0 ? "checked" : ""
              }`}
            >
              {t("homeContainer.deviceType.Lighting")}
            </div>
            <div
              onClick={toggleDeviceType(DeviceTypes.SHUTTER)}
              className={`card-checkbox-container-item shutter ${
                deviceTypes.indexOf(DeviceTypes.SHUTTER) >= 0 ? "checked" : ""
              }`}
            >
              {t("homeContainer.deviceType.Shutter")}
            </div>
            <div
              onClick={toggleDeviceType(DeviceTypes.HEATING)}
              className={`card-checkbox-container-item heating ${
                deviceTypes.indexOf(DeviceTypes.HEATING) >= 0 ? "checked" : ""
              }`}
            >
              {t("homeContainer.deviceType.Heat")}
            </div>
          </div>
        </Card>
      </div>
    </StepContainer>
  );
};

export default HomeContainer;
