import { FC } from "react";
import Icon, { IconProps } from "./Icon";

const House: FC<IconProps> = (props) => (
  <Icon height={24} width={24} {...props} viewBox="0 0 90.731 98.982">
    <path
      id="Path_1"
      data-name="Path 1"
      d="M88.2,99.5H8.7a3.3,3.3,0,0,1-2.2-.8,2.271,2.271,0,0,1-.9-1.8V66A18.963,18.963,0,0,1,9,56.7a15.9,15.9,0,0,1,6.7-5.5,32.88,32.88,0,0,1,7.6-2.3,29.028,29.028,0,0,1,7.1-.4c7.4,0,13,2.1,16.8,6.2,2.9,2.9,4.4,6.9,4.4,11.9V81c0,1.7-1,2.6-3.1,2.6A2.325,2.325,0,0,1,45.9,81V66c0-3.3-.9-5.8-2.8-7.7-2.8-2.9-7.1-4.4-12.9-4.4C18.3,53.8,12,58,11.3,66.2V94.1H85.6V43L45.9,7.1,5.1,43.5a2.445,2.445,0,0,1-3.9-.3,2.445,2.445,0,0,1,.3-3.9L43.8,1.2a2.723,2.723,0,0,1,3.9.3L90.3,39.7a3.678,3.678,0,0,1,.8,2.1V97a2.271,2.271,0,0,1-.9,1.8A4.382,4.382,0,0,1,88.2,99.5Z"
      transform="translate(-0.369 -0.518)"
    />
  </Icon>
);

export default House;
