export const gaActions = {
  clickSummary: "click_summary",
  clickHeader: "click_header",
  clickBackContainer: "click_back_container",
  clickDeviceType: "click_device_type",
  clickNavigation: "click_navigation",
  clickNavigationPropertyLayout: "click_navigation_property_layout",
  clickPropertyLayout: "click_property_layout",
  clickPropertyType: "click_property_type",
  chooseOwnLayout: "choose_your_own_layout",
  connectedSwitch: "connected_switch",
  display: "display",
  displayBom: "display_bom",
  addToCartBom: "add_to_cart_bom",
  completePropertyLayoutStep: "complete_property_layout_step",
};
