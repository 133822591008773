import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { gaActions } from "../../utils/constants";
import { trackEvents } from "../../utils/tracking/tracking";
import "./styles.scss";
import StepContainer from "../StepContainer";
import axios from "axios";
import GlobalLoader from "../GlobalLoader";
import { Question } from "./components";
import { DeviceTypes, QuizFlow } from "../../shared/types";

interface QuizContainerProps {
  step: number;
  setNextStep: () => void;
  finished: boolean;
  setPreviousStep: () => void;
  previousStep: any;
  questions: any[];
  currentQuestionNumber: number;
  activeDeviceType: DeviceTypes;
  deviceTypes: DeviceTypes[];
  popQuestion: () => void;
  setDeviceTypes: (deviceTypes: DeviceTypes[]) => void;
  pushSelection: (result: any) => void;
  setNextActiveDeviceType: (deviceType: DeviceTypes | undefined) => void;
  popDeviceType: () => void;
  addQuestion: (question: any) => void;
  setQuestionAnswered: (questionValue: string) => (answer: string) => void;
  results: any[];
  popResult: () => void;
  quizFlow: QuizFlow;
}

const areAllQuestionsAnswered = (questions: any[]) => {
  return questions.reduce((prev, current) => {
    return prev && !!current.answer;
  }, true);
};

const mapPayload = (questions: any[]) => {
  return questions.reduce((prev, current) => {
    prev[current.questionValue] = current.answer;
    return prev;
  }, {});
};

const QuizContainer: FC<QuizContainerProps> = ({
  step,
  setNextStep,
  deviceTypes,
  pushSelection,
  setPreviousStep,
  popResult,
  previousStep,
  questions,
  currentQuestionNumber,
  activeDeviceType,
  quizFlow,
  results,
  popDeviceType,
  addQuestion,
  popQuestion,
  setQuestionAnswered,
  finished,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [t] = useTranslation();

  const stringifiedQuestions = JSON.stringify(mapPayload(questions));

  useEffect(() => {
    if (
      questions.length > 0 &&
      areAllQuestionsAnswered(questions) &&
      quizFlow === QuizFlow.Forward
    ) {
      handleNext();
    }
  }, [stringifiedQuestions]);

  useEffect(() => {
    if (finished) {
      return;
    }
    fetchNewQuestion();
  }, [activeDeviceType]);

  const fetchNewQuestion = () => {
    setIsLoading(true);
    return axios
      .post("https://wiser-api.sesmartconfig.com/question", {
        deviceTypes: [
          {
            type: activeDeviceType,
            answeredQuestions: mapPayload(questions),
          },
        ],
      })
      .then((res) => {
        const { finished, nextQuestion } = res.data[0];
        setIsLoading(false);
        if (finished) {
          pushSelection({
            deviceType: activeDeviceType,
            questions,
            finished,
            currentQuestionNumber,
            answeredQuestions: mapPayload(questions),
          });

          if (deviceTypes.length > 0) {
            popDeviceType();
          } else {
            if (previousStep === 2) {
              return;
            }

            setNextStep();
          }
        } else {
          addQuestion(nextQuestion);
        }
        // check finished
      });
  };

  const handleNext = () => {
    trackEvents({
      action: gaActions.clickNavigation,
      label: "next",
      nonInteraction: false,
      nuEventName: `${gaActions.clickNavigation}_next`,
    });
    if (previousStep === 2) {
      pushSelection({
        deviceType: activeDeviceType,
        questions,
        finished,
        currentQuestionNumber,
        answeredQuestions: mapPayload(questions),
      });
      setNextStep();
      return;
    }

    fetchNewQuestion();
  };

  const handleBack = () => {
    trackEvents({
      action: gaActions.clickNavigation,
      label: "back",
      nonInteraction: false,
      nuEventName: `${gaActions.clickNavigation}_back`,
    });
    if (questions.length > 1) {
      popQuestion();
    } else if (results.length > 0) {
      popResult();
    } else if (questions?.length === 1) {
      setPreviousStep();
    }
    if (questions.length === 0) {
      setPreviousStep();
    }
  };

  const canProgress = areAllQuestionsAnswered(questions);

  const handleQuestionAnswered =
    (questionValue: string) => (answer: string) => {
      setQuestionAnswered(questionValue)(answer);
    };

  return (
    <>
      <StepContainer
        canProgress={canProgress}
        onBack={handleBack}
        onNext={handleNext}
      >
        <div className="QuizContainer">
          <div className="DeviceType">{`${t("Configuring")} ${t(
            "homeContainer.deviceType." + activeDeviceType
          )}`}</div>
          <div className="Container">
            {questions.map(
              ({ questionValue, answers, answer, questionType }, i) => {
                return (
                  <Question
                    key={`question_${i}`}
                    step={step}
                    activeDeviceType={activeDeviceType}
                    title={questionValue}
                    options={answers}
                    answer={answer}
                    definition={questionType}
                    disabled={!!answer && i < questions.length - 1}
                    onAnswer={handleQuestionAnswered(questionValue)}
                    visible={i >= questions.length - 3 && i < questions.length}
                  />
                );
              }
            )}
          </div>
        </div>
      </StepContainer>
      {isLoading && <GlobalLoader />}
    </>
  );
};

export default QuizContainer;
