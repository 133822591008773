import React, { FC } from 'react'

export interface IconProps {
  height?: number
  width?: number
  fill?: string
  stroke?: string
  viewBox?: string
}

const Icon: FC<IconProps> = ({
  children,
  height,
  width,
  fill,
  stroke,
  viewBox
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewBox} fill={fill} stroke={stroke} x="0px" y="0px" height={height} width={width}>
    {children}
  </svg>
)

export default Icon
