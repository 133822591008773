import { createContext } from "react";
import { ConfiguratorState } from "../shared/types";

const initialState: ConfiguratorState = {
  deviceTypes: [],
  activeDeviceType: "",
  propertyType: "",
  toggleDeviceType: (deviceType: string) => {},
  results: [],
  pushSelection: () => {},
  questions: [],
  finished: false,
  currentQuestionNumber: 0,
};

const AppContext = createContext<ConfiguratorState>(initialState);
AppContext.displayName = "AppContext";

export default AppContext;
