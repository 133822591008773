import { useState, useEffect, useContext, useReducer } from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import { trackEvents } from "../../utils/tracking/tracking";
import { gaActions } from "../../utils/constants";

import {
  RecommendedProduct,
  SelectedProduct,
  AccessoryModal,
  BrandSelector,
} from "./components";
import AppContext from "../../context/AppContext";
import axios from "axios";
import {
  ConfiguratorState,
  DeviceTypes,
  IProduct,
  IResult,
} from "../../shared/types";
import { Card, FieldGroup, FieldLabel, FieldValue, StepContainer } from "..";
import {
  default as Button,
  ButtonSize,
  ButtonType,
} from "../../shared/components/Button";
import { v4 as uuidv4 } from "uuid";

enum CartProductType {
  regular = "REGULAR",
  suggestion = "SUGGESTION",
  selectedSuggestion = "SELECTED_SUGGESTION",
}

interface BOMContainerProps {
  step: number;
  setNextStep: () => void;
  setPreviousStep: () => void;
  results: IResult[];
  previousStep: any;
  resetState: () => void;
}

interface IDevice {
  availableSuggestions: any[];
  selectedSuggestions: any[];
  deviceType: DeviceTypes;
  results: IProduct[];
  totalPrice: string;
  totalQuantity: number;
}

interface IBrandResult {
  Odace?: any;
  Uniqa?: any;
}

interface IBrandOption {
  deviceType: DeviceTypes;
  resultsByBrand: IBrandResult[];
  selectedBrand: string;
}

interface IBomState {
  devices: IDevice[];
  brandOptions?: IBrandOption[];
  results: any[];
  selectedSuggestions: any[];
  availableSuggestions: any[];
  totalPrice: string;
  totalQuantity: number;
}

interface ICartItem {
  id: string;
  deviceType: DeviceTypes;
  cartType: CartProductType;
  quantity: number;
  price: string;
}

interface IBrandSelection {}

enum ActionType {
  SetBomResponse = "SET_BOM_RESPONSE",
}

const getInitialBrandSelection = () => {
  let InitialBrandSelection: IBrandSelection = {
    [DeviceTypes.HEATING]: [],
    [DeviceTypes.LIGHTNING]: [],
    [DeviceTypes.SHUTTER]: [],
  };
  return InitialBrandSelection;
};

const initialBomState: IBomState = {
  devices: [],
  results: [],
  availableSuggestions: [],
  selectedSuggestions: [],
  totalQuantity: 0,
  totalPrice: "0",
  ...getInitialBrandSelection(),
};

// const bomReducerinitialState = {
//   ...initialBomState,
//   ...InitialBrandSelection,
// };

const mapProductsToCart = (cartProducts: ICartItem[]): any => {
  return cartProducts
    .filter((cartItem: ICartItem) => cartItem.quantity > 0)
    .map((cartItem: ICartItem) => ({
      reference: cartItem.id,
      quantity: cartItem.quantity,
      price: cartItem.price,
    }));
};

const isAddedToCart = (productId: string, array: any[] = []) => {
  return array.findIndex((x) => x.id === productId) >= 0;
};

const getUniqueDeviceTypes = (results: any[]) => {
  const deviceTypes: DeviceTypes[] = [];
  results?.forEach((res: IResult) => {
    if (
      !!res.deviceType &&
      deviceTypes.findIndex((x) => x === res.deviceType) < 0
    ) {
      deviceTypes.push(res.deviceType);
    }
  });

  return deviceTypes;
};

const reducer = (state: IBomState, action: any) => {
  switch (action.type) {
    case ActionType.SetBomResponse:
      return {
        ...state,
        ...action.payload,
        ...getInitialBrandSelection(),
      };
    default:
      throw new Error();
  }
};

const BOMContainer = ({ setPreviousStep, resetState }: BOMContainerProps) => {
  const [t] = useTranslation();
  const appState = useContext<ConfiguratorState>(AppContext);
  // const [bomState, setBomState] = useState<IBomState>(initialBomState);
  const [recommendedProductsInCart] = useState<any>([]);
  const [cartProducts, setCardProducts] = useState<any>([]);
  const [selectedBrandOptions, setSelectedBrandOptions] = useState<any>(
    getInitialBrandSelection()
  );

  const [bomState, dispatch] = useReducer(reducer, initialBomState);

  const setBomResponse = (data: any) => {
    dispatch({
      type: ActionType.SetBomResponse,
      payload: data,
    });
  };

  const [isAccessoryModalOpen, setAccessoryModalOpen] =
    useState<boolean>(false);
  const stringifiedProducts = JSON.stringify(cartProducts);

  const addResultsToCart = ({
    results = [],
    selectedSuggestions = [],
  }: any) => {
    results.forEach((res: any) => {
      let index = cartProducts.findIndex((x: any) => x.id === res.id);
      if (index < 0) {
        cartProducts.push({
          id: res.id,
          quantity: res.quantity || 1,
          price: res.price || 0,
          cartType: CartProductType.regular,
        });
      }
    });
    selectedSuggestions.forEach((res: any) => {
      let index = cartProducts.findIndex((x: any) => x.id === res.id);
      if (index < 0) {
        cartProducts.push({
          id: res.id,
          quantity: res.quantity || 1,
          price: res.price || 0,
          cartType: CartProductType.regular,
        });
      }
    });
    setCardProducts([...cartProducts]);
  };

  const addRecommendedProductsToCart = (products: any[]) => {
    products.forEach((data) => {
      const index = cartProducts.findIndex((x: any) => x.id === data.id);
      if (index >= 0) {
        return;
      }

      cartProducts.push({
        cartType: CartProductType.suggestion,
        id: data.id,
        price: data.price,
        quantity: 1,
      });
    });
    setCardProducts([...cartProducts]);
  };

  useEffect(() => {
    axios
      .post("https://wiser-api.sesmartconfig.com/report/bom", {
        deviceTypes: appState.results.map((result: IResult) => ({
          type: result.deviceType,
          answeredQuestions: result.answeredQuestions,
          suggestionIds: cartProducts
            .filter((x: any) => x.cartType === CartProductType.suggestion)
            .map((x: any) => x.id),
        })),
        livingRooms: appState.layoutType?.livingRoom,
        bedRooms: appState.layoutType?.bedrooms,
      })
      .then((res) => res.data)
      .then((data: IBomState) => {
        // setSelectedBrandOptions(getInitialBrandSelection());
        addResultsToCart(data);
        // setBomState(JSON.parse(JSON.stringify(data)));
        setBomResponse(data);
      });

    return () => {};
  }, [stringifiedProducts]);

  const handleBack = () => {
    return () => {
      trackEvents({
        action: gaActions.clickBackContainer,
        label: "cart",
        nonInteraction: false,
        nuEventName: `${gaActions.clickBackContainer}_cart`,
      });
      setPreviousStep();
    };
  };

  const handleBrandSelection = (deviceType: DeviceTypes, products: any[]) => {
    selectedBrandOptions[deviceType] = products;
    setSelectedBrandOptions(JSON.parse(JSON.stringify(selectedBrandOptions)));
  };

  const handleCartIncrease = (productId: string) => () => {
    const index = cartProducts.findIndex((x: any) => x.id === productId);
    if (index < 0) {
      return;
    }
    cartProducts[index].quantity++;
    setCardProducts([...cartProducts]);
  };

  const handleCartDecrease = (productId: string) => () => {
    const index = cartProducts.findIndex((x: any) => x.id === productId);
    if (index < 0) {
      return;
    }

    if (cartProducts[index].quantity === 0) {
      return;
    }

    cartProducts[index].quantity--;

    setCardProducts([...cartProducts]);
  };

  let products: any[] = [];

  products = [
    ...products,
    ...bomState?.results?.map((res: any) => ({
      ...res,
    })),
    ...bomState?.selectedSuggestions?.map((res: any) => ({
      ...res,
    })),
  ];

  useEffect(() => {
    trackEvents({
      action: gaActions.displayBom,
      label: "summary",
      nuEventName: `${gaActions.displayBom}_summary`,
    });
  }, []);

  const getSelectedProducts = () => {
    let brandProducts: any[] = [];
    Object.keys(selectedBrandOptions).forEach((key: string) => {
      brandProducts = [
        ...brandProducts,
        ...selectedBrandOptions[key].map((item: any) => ({
          reference: item.id,
          quantity: item.quantity,
          price: item.price,
        })),
      ];
    });
    return [...mapProductsToCart(cartProducts), ...brandProducts];
  };

  const handleAddToCart = () => {
    trackEvents({
      action: gaActions.addToCartBom,
      nonInteraction: false,
      nuEventName: gaActions.addToCartBom,
    });
    var data = {
      options: {
        id: uuidv4(), // unique id for each "add to cart"
        products: getSelectedProducts(),
        quantity: 1, // number of times we should add the configuration
        extras: {
          pluginId: "Wiser Configurator", // name of the plugin which has to be set by plugin
        },
      },
      type: "ScWidgetSaveConfigurationEvent",
    };
    const allProds = data.options.products;
    const nextuserTrackProducts = allProds.map(p => ({id: p.reference, quantity: p.quantity}));
    
    try {
      window.nextuser.track('add_to_cart_bom_cart_state', JSON.stringify(nextuserTrackProducts));
    }catch(ex){

    }
    
    let totalProds = 0;
    let totalPrice = 0;

    for (let i = 0, prod; (prod = allProds[i]); i++) {
      totalProds += prod.quantity;
      totalPrice += parseFloat(prod.price) * prod.quantity;
    }
    trackEvents({
      action: gaActions.addToCartBom,
      label: `total_products_${totalProds.toString()}`,
      value: totalProds,
      nonInteraction: false,
      nuEventName: `${
        gaActions.addToCartBom
      }_total_products_${totalProds.toString()}`,
    });

    trackEvents({
      action: gaActions.addToCartBom,
      label: `total_price_${totalPrice.toString()}`,
      value: totalPrice,
      nonInteraction: false,
      nuEventName: `${
        gaActions.addToCartBom
      }_total_price_${totalPrice.toString()}`,
    });

    console.log(data);

    if (!window.parent) {
      return;
    }

    window.parent.postMessage(data, "*");
  };

  const cartGetProductQuantity = (productId: string) => {
    return cartProducts?.find((x: any) => x.id === productId)?.quantity || 0;
  };

  const availableDeviceTypes: any[] = getUniqueDeviceTypes(appState?.results);

  const handleConfirmAccessories = (products: any[]) => {
    const suggestions = bomState?.availableSuggestions || [];
    addRecommendedProductsToCart(
      suggestions
        .filter((x: any) => !isAddedToCart(x.id, recommendedProductsInCart))
        .filter((x: any) => products.findIndex((y) => y === x.id) >= 0)
    );

    trackEvents({
      action: gaActions.clickSummary,
      label: "recommendation_added",
      nonInteraction: false,
      nuEventName: `${gaActions.clickSummary}_recommendation_added`,
    });
    setAccessoryModalOpen(false);
  };

  useEffect(() => {
    trackEvents({
      action: gaActions.displayBom,
      label: "summary",
      nuEventName: `${gaActions.displayBom}_summary`,
    });
  }, []);

  const showNoResult =
    products.length === 0 &&
    (bomState.brandOptions === undefined ||
      bomState?.brandOptions?.length === 0);
  return (
    <StepContainer onBack={handleBack()}>
      <div className="BOMContainer">
        <div className="title">
          <span>{t("bom.selectedProductsTitle")}</span>
          <div className="BOMContainer__Controls">
            {bomState?.availableSuggestions.length > 0 && (
              <Button
                onClick={() => setAccessoryModalOpen(true)}
                type={ButtonType.Secondary}
                size={ButtonSize.Large}
              >
                {t("bom.accessories")}
              </Button>
            )}
            <Button
              onClick={handleAddToCart}
              type={ButtonType.Primary}
              size={ButtonSize.Large}
            >
              {t("bom.buyNow")}
            </Button>
          </div>
        </div>
        <div className="row">
          <div className="Selection">
            <Card title={t("")}>
              <FieldGroup>
                <FieldLabel value={t("homeContainer.choosePropertyType")} />
                <FieldValue value={appState?.layoutType?.title || "N/A"} />
              </FieldGroup>
              <FieldGroup>
                <FieldLabel value={t("homeContainer.choosePropertyLayout")} />
                <FieldValue value={t(`bom.${appState.propertyType}`)} />
              </FieldGroup>
              <FieldGroup>
                <FieldLabel value={t("homeContainer.chooseDeviceType")} />
                <>
                  {availableDeviceTypes.map((dt) => (
                    <FieldValue
                      key={dt}
                      value={t("homeContainer.deviceType." + dt)}
                    />
                  ))}
                </>
              </FieldGroup>
              {appState.results.map((res: any) => {
                return Object.keys(res.answeredQuestions).map((key) => {
                  return (
                    <FieldGroup>
                      <FieldLabel value={key} />
                      <FieldValue value={res.answeredQuestions[key]} />
                    </FieldGroup>
                  );
                });
              })}
              <div style={{ textAlign: "center" }}>
                <Button
                  onClick={resetState}
                  size={ButtonSize.Small}
                  type={ButtonType.Primary}
                >
                  {t("general.reset")}
                </Button>
              </div>
            </Card>
          </div>
          <div className="Products">
            {showNoResult && (
              <div className="ProductsContainer--Empty">
                {t("Wiser n'est pas compatible")}
              </div>
            )}
            {bomState?.brandOptions?.map((definition: any, index: number) => (
              <BrandSelector
                key={`BrandSelector__${index}`}
                definition={definition}
                selection={selectedBrandOptions[definition.deviceType] as any}
                onBrandSelection={handleBrandSelection}
              />
            ))}
            {products.length > 0 &&
              products.map((product) => {
                return (
                  <Card
                    key={`selectedProduct-${product.id}}`}
                    title=""
                    subtitle={
                      product.deviceType &&
                      t("homeContainer.deviceType." + product.deviceType)
                    }
                  >
                    <SelectedProduct
                      product={product}
                      quantity={cartGetProductQuantity(product.id)}
                      onIncrease={handleCartIncrease(product.id)}
                      onDecrease={handleCartDecrease(product.id)}
                    />
                  </Card>
                );
              })}
          </div>
        </div>
        {bomState?.results?.length > 0 &&
          bomState?.availableSuggestions?.length > 0 && (
            <div className="accessories-container">
              <div className="title">
                <span>{t("bom.recommendedProducts")}</span>
              </div>
              <div className="RecommendedProducts">
                {bomState?.availableSuggestions.map((rp: any) => {
                  return (
                    <RecommendedProduct
                      key={`recommendedProduct-${rp.id}}`}
                      title={rp.title}
                      reference={rp.id}
                      imageUrl={rp.imageUrl}
                      description={rp.description}
                      onClick={() => handleConfirmAccessories([rp.id])}
                    />
                  );
                })}
              </div>
            </div>
          )}
        <AccessoryModal
          isOpen={isAccessoryModalOpen}
          onRequestClose={() => setAccessoryModalOpen(false)}
          items={bomState?.availableSuggestions || []}
          onValidate={handleConfirmAccessories}
        />
      </div>
    </StepContainer>
  );
};

export default BOMContainer;
