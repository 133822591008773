import { useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { RecommendedProduct } from "..";

import "./styles.scss";

interface AccessoryModalProps {
  isOpen: boolean;
  onRequestClose(): void;
  onValidate: (productIds: string[]) => void;
  items: any[];
}

const AccessoryModal = ({
  isOpen,
  onRequestClose,
  onValidate,
  items,
}: AccessoryModalProps) => {
  const [t] = useTranslation();
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);

  const handleValidateClick = () => {
    onValidate(selectedProducts);
  };

  const toggleProduct = (productId: string) => {
    const index = selectedProducts.indexOf(productId);
    if (index >= 0) {
      selectedProducts.splice(index, 1);
    } else {
      selectedProducts.push(productId);
    }

    setSelectedProducts([...selectedProducts]);
  };
  const handleModalOpen = () => {
    setSelectedProducts([]);
  };

  return (
    <Modal id="AccessoryModal" isOpen={isOpen} onAfterOpen={handleModalOpen}>
      <div className="AccessoryModal__Header">
        <div className="AccessoryModal__Header__Title">
          {t("bom.accessories")}
        </div>
        <div className="AccessoryModal__Header__Right">
          <button onClick={onRequestClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="27"
              height="27"
              viewBox="0 0 27 27"
            >
              <g id="Group_2" data-name="Group 2" transform="translate(0 27)">
                <path
                  id="Path_2"
                  data-name="Path 2"
                  d="M3.938-23.062A13.019,13.019,0,0,1,13.5-27a13.019,13.019,0,0,1,9.563,3.938A13.019,13.019,0,0,1,27-13.5a13.019,13.019,0,0,1-3.937,9.563A13.019,13.019,0,0,1,13.5,0,13.019,13.019,0,0,1,3.938-3.937,13.019,13.019,0,0,1,0-13.5,13.019,13.019,0,0,1,3.938-23.062Zm14.625,13.5L14.555-13.5l4.008-3.937a.71.71,0,0,0-.035-1.09.71.71,0,0,0-1.09-.035L13.5-14.555,9.563-18.562a.71.71,0,0,0-1.09.035.71.71,0,0,0-.035,1.09L12.445-13.5,8.438-9.562v.07a.688.688,0,0,0,0,1.055h.07a.688.688,0,0,0,1.055,0L13.5-12.445l3.938,4.008h.07a.688.688,0,0,0,1.055,0v-.07A.688.688,0,0,0,18.563-9.562Z"
                  fill="#909090"
                />
              </g>
            </svg>
          </button>
        </div>
      </div>
      <div className="AccessoryModal__Body">
        {items.map((rp) => {
          return (
            <RecommendedProduct
              key={`recommendedProduct-${rp.id}}`}
              title={rp.title}
              reference={rp.id}
              imageUrl={rp.imageUrl}
              description={rp.description}
              onClick={() => toggleProduct(rp.id)}
              selected={selectedProducts.indexOf(rp.id) >= 0}
            />
          );
        })}
      </div>
      <div className="AccessoryModal__Controls">
        <button
          onClick={handleValidateClick}
          className="Button Button--Primary"
        >
          {t("general.validate")}
        </button>
      </div>
    </Modal>
  );
};

export default AccessoryModal;
