import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../shared/components";
import { ButtonSize, ButtonType } from "../../../../shared/components/Button";
import { trackEvents } from "../../../../utils/tracking/tracking";
import { gaActions } from "../../../../utils/constants";
import {
  DeviceTypes,
  IBrandDefinition,
  IProduct,
} from "../../../../shared/types";
import { BrandPickerItem, ColorPickerItem } from "./components";
import "./styles.scss";

const DefaultColor = "WHITE";

interface IBrandSelectorProps {
  definition: IBrandDefinition;
  selection: any;
  onBrandSelection: (deviceType: DeviceTypes, products: any[]) => void;
}

const updateSelection = (prevSelection: any[], newProducts: any[]): any[] => {
  const newSelection = newProducts.map((p: any) => ({ ...p }));
  for (
    var i: number = 0, prevProduct: any;
    (prevProduct = prevSelection[i]);
    i++
  ) {
    const index = newSelection.findIndex(
      (p: any) => p.title === prevProduct.title
    );
    if (index >= 0) {
      newSelection[index].quantity = prevProduct.quantity;
    }
  }

  return newSelection;
};

const BrandSelector = ({
  definition,
  selection,
  onBrandSelection,
}: IBrandSelectorProps) => {
  const [selectedBrand, setSelectedBrand] = useState(definition.selectedBrand);
  const [selectedColor, setSelectedColor] = useState(DefaultColor);
  const [availableColors, setAvailableColors] = useState<string[]>([]);
  const [availableBrands, setAvailableBrands] = useState<string[]>([]);
  const [isFlipped, setFlipped] = useState<boolean>(Boolean);
  const [t] = useTranslation();

  useEffect(() => {
    if (!definition) {
      return;
    }
    const brands = Object.keys(definition.resultsByBrand);
    setAvailableBrands(brands);
    const selectedBrand = definition.selectedBrand || brands[0];
    setSelectedBrand(selectedBrand);

    return () => {};
  }, [JSON.stringify(definition)]);

  useEffect(() => {
    if (!selectedBrand || !selectedColor) {
      return;
    }
    const selectedProducts =
      definition?.resultsByBrand[selectedBrand][selectedColor] || [];
    onBrandSelection(
      definition.deviceType,
      updateSelection(selection, selectedProducts)
    );
  }, [selectedColor]);

  useEffect(() => {
    if (!selectedBrand) {
      return;
    }
    const colors = Object.keys(definition?.resultsByBrand[selectedBrand]) || [];
    setAvailableColors(colors);
    setSelectedColor(DefaultColor);
  }, [selectedBrand]);

  const handleRangePicker = (selectedBrand: string) => {
    trackEvents({
      action: `${gaActions.clickSummary}_${gaActions.connectedSwitch}`,
      label: `range_change_${selectedBrand}`,
      nonInteraction: false,
      nuEventName: `${gaActions.clickSummary}_${gaActions.connectedSwitch}_range_change_${selectedBrand}`,
    });
    return setSelectedBrand(selectedBrand);
  };

  const handleColorPicker = (selectedColor: string) => {
    trackEvents({
      action: `${gaActions.clickSummary}_${gaActions.connectedSwitch}`,
      label: `color_change_${selectedColor}`,
      nonInteraction: false,
      nuEventName: `${gaActions.clickSummary}_${gaActions.connectedSwitch}_color_change_${selectedColor}`,
    });
    return setSelectedColor(selectedColor);
  };

  const handleCloseIcon = (closeBool: boolean) => {
    trackEvents({
      action: `${gaActions.clickSummary}_${gaActions.connectedSwitch}`,
      label: "close",
      nonInteraction: false,
      nuEventName: `${gaActions.clickSummary}_${gaActions.connectedSwitch}_close`,
    });
    return setFlipped(closeBool);
  };

  const handleModifyBtn = (closeBool: boolean) => {
    trackEvents({
      action: `${gaActions.clickSummary}_${gaActions.connectedSwitch}`,
      label: "modify",
      nonInteraction: false,
      nuEventName: `${gaActions.clickSummary}_${gaActions.connectedSwitch}_modify`,
    });
    return setFlipped(closeBool);
  };

  const handleIncrease = (productId: string) => {
    const index = selection.findIndex((x: any) => x.id === productId);
    if (index >= 0) {
      selection[index].quantity++;
      onBrandSelection(definition.deviceType, selection);
    }
  };

  const getQuantity = (productId: string) => {
    return selection.find((x: any) => x.id === productId)?.quantity || 1;
  };

  const handleDecrease = (productId: any) => {
    const quantity = getQuantity(productId);
    if (quantity === 1) {
      return;
    }

    const index = selection.findIndex((x: any) => x.id === productId);
    if (index >= 0) {
      selection[index].quantity--;
      onBrandSelection(definition.deviceType, selection);
    }
  };

  const getProducts = () => {
    if (!selectedBrand || !selectedColor) {
      return [];
    }

    return definition.resultsByBrand[selectedBrand][selectedColor];
  };

  return (
    <div
      className={`BrandSelector ${isFlipped ? "BrandSelector--Flipped" : ""}`}
    >
      <div className="BrandSelector__ProductContainer">
        <h6 className="BrandSelector__DeviceType">
          {definition?.deviceType &&
            t("homeContainer.deviceType." + definition.deviceType)}
        </h6>
        <div className="BrandSelector__Products">
          {getProducts().map((product: IProduct) => (
            <div
              key={`BrandSelector__Product__${product.id}`}
              className="BrandSelector__Product"
            >
              <div className="BrandSelector__Product__Image">
                <img alt={product.title} src={product.imageUrl} />
              </div>
              <div className="BrandSelector__Product__Title">
                {product.title || "N/A"}
              </div>
              <div className="BrandSelector__Product__Reference">
                {product.id}
              </div>
              <div className="BrandSelector__Product__Quantity">
                <button
                  onClick={() => handleDecrease(product.id)}
                  type="button"
                  className="BrandSelector__Product__Quantity__Decrease"
                >
                  -
                </button>
                <div className="BrandSelector__Product__Quantity__Value">
                  {getQuantity(product.id) || 1}
                </div>
                <button
                  onClick={() => handleIncrease(product.id)}
                  type="button"
                  className="BrandSelector__Product__Quantity__Increase"
                >
                  +
                </button>
              </div>
            </div>
          ))}
        </div>
        <div className="BrandSelector__Controls">
          <label>{t("bom.createYourOwnSelection")}</label>
          <Button
            size={ButtonSize.Large}
            type={ButtonType.Primary}
            onClick={() => handleModifyBtn(true)}
          >
            {t("shared.modify")}
          </Button>
        </div>
      </div>
      <div className="BrandSelector__Configuration">
        <div className="BrandSelector__Header">
          <div className="BrandSelector__Header__Title">
            {t("bom.editConnectedSwitch")}
          </div>
          <div className="BrandSelector__Header__Close">
            <button onClick={() => handleCloseIcon(false)}>✖</button>
          </div>
        </div>
        <div className="BrandSelector__Configuration__Title">
          {t("bom.chooseARange")}
        </div>
        <div className="BrandSelector__Configuration__BrandPicker">
          {availableBrands.map((x) => (
            <BrandPickerItem
              brand={x}
              onClick={() => handleRangePicker(x)}
              key={`Picker__Item--${x}`}
              isActive={x === selectedBrand}
            />
          ))}
        </div>
        <div className="BrandSelector__Configuration__Title">
          {t("bom.chooseAColor")}
        </div>
        <div className="BrandSelector__Configuration__ColorPicker">
          {availableColors.map((x) => (
            <ColorPickerItem
              key={`Color__${x}`}
              colorName={x}
              isActive={x === selectedColor}
              onClick={() => handleColorPicker(x)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default BrandSelector;
