import "./styles.scss";

interface IColorPickerItemProps {
  brand: string;
  onClick: () => void;
  isActive: boolean;
}

const ImageMap = {
  Unica:
    "https://nu-platform-prod-168.storage.googleapis.com/gallery/a647b06af1704b67af2f0ecc1873e7d2.png",
  Odace:
    "https://nu-platform-prod-168.storage.googleapis.com/gallery/dc0b3c201461452198bd9c31a3b4209d.png",
};

const BrandPickerItem = ({
  brand,
  onClick,
  isActive,
}: IColorPickerItemProps) => {
  return (
    <div
      className={`BrandPicker__Item ${
        isActive ? "BrandPicker__Item--Active" : ""
      }`}
    >
      <div className="BrandPicker__Item__Image">
        <img src={ImageMap[brand as keyof typeof ImageMap]} alt={brand} />
      </div>
      <div className="BrandPicker__Item__Button">
        <button onClick={onClick}>{brand}</button>
      </div>
    </div>
  );
};

export default BrandPickerItem;
