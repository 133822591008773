import "./styles.scss";

interface IColorPickerItemProps {
  colorName: string;
  isActive: boolean;
  onClick: () => void;
}
interface IColorMap {
  [key: string]: string;
}

const ColorMap: IColorMap = {
  aluminium: "#99A6AA",
  white: "#F0F2F4",
  antracite: "#1A1F29",
  antibacterial: "#E0E0DE",
};
const ColorPickerItem = ({
  onClick,
  colorName,
  isActive,
}: IColorPickerItemProps) => {
  return (
    <div
      onClick={onClick}
      className={`ColorPicker__Item ${
        isActive ? "ColorPicker__Item--Active" : ""
      }`}
      style={{
        background:
          ColorMap[colorName.toLocaleLowerCase() as keyof typeof ColorMap],
      }}
    ></div>
  );
};

export default ColorPickerItem;
