import "./styles.scss";
interface StepItemProps {
  title: string;
  id: number;
  isActive: boolean;
  onClick: (step: number) => void;
}

const StepItem = ({ id, title, isActive, onClick }: StepItemProps) => {
  const handleStepperItemClick = () => {
    onClick(id);
  };

  return (
    <div
      onClick={handleStepperItemClick}
      className={`StepItem ${isActive ? "active" : ""}`}
    >
      <div className="StepItem-Id">{id + 1}</div>
      <div className="StepItem-Label">{title}</div>
    </div>
  );
};

export default StepItem;
