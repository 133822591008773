export interface RoomDefinition {
  title: string;
  image: string;
  key: string;
}

export interface IRoomState {
  [key: string]: number;
}

export enum RoomListTypes {
  DEFAULT = 'default',
  DETAILS = 'details',
}