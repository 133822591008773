import ReactGA from "react-ga";

interface trackProps {
  gaCategory: string;
  gaAction: string;
  gaLabel?: string;
  gaNonInteraction?: boolean;
  gaValue?: number;
}

export const ga = ({
  gaCategory,
  gaAction,
  gaLabel,
  gaNonInteraction = true,
  gaValue = undefined,
}: trackProps) => {
  const trackObj = {
    category: gaCategory,
    action: gaAction,
    label: gaLabel,
    nonInteraction: gaNonInteraction,
    value: gaValue,
  };

  ReactGA.event(trackObj);
};
