import React, { FC } from 'react'
import { ChevronLeft, ChevronRight } from '../icons'
import './styles.scss'

interface StepContainerProps {
  canProgress?: boolean
  onBack?: () => void
  onNext?: () => void
}

const StepContainer: FC<StepContainerProps> = ({ canProgress, children, onBack, onNext }) => {

  return (
    <div className="StepContainer">
      <div className="StepContainer-prev StepContainer-action">
        {
          !!onBack &&
          <button onClick={onBack} className="success">
            <ChevronLeft height={30} fill="#3AB85D" />
          </button>
        }
      </div>
      <div className="StepContainer-content">
        {children}
      </div>
      <div className="StepContainer-next StepContainer-action">
        {
          !!onNext &&
          <button disabled={!canProgress} onClick={onNext} className={canProgress ? 'success' : ''}>
            <ChevronRight height={30} fill={canProgress ? '#3AB85D' : undefined} />
          </button>
        }
      </div>
    </div>
  )
}

export default StepContainer
