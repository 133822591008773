import { TFunctionResult } from "i18next";
import { ReactChild } from "react";
import "./styles.scss";

interface IButtonProps {
  children: ReactChild | ReactChild[] | TFunctionResult;
  type: ButtonType;
  size: ButtonSize;
  onClick?: () => void;
}

export enum ButtonType {
  Primary = "Primary",
  Secondary = "Secondary",
}

export enum ButtonSize {
  Small = "sm",
  Medium = "md",
  Large = "lg",
}

const Button = ({
  children,
  type = ButtonType.Primary,
  size = ButtonSize.Medium,
  onClick,
}: IButtonProps) => {
  return (
    <button
      onClick={onClick}
      className={`Button Button--${type} Button--${size}`}
    >
      {children}
    </button>
  );
};

export default Button;
