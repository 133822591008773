declare global {
  interface Window {
    nextuser: any;
  }
}

const nextuser = function (categoryPrefix: string, eventNameNu: string, params:string[] = []) {
  try {
    const NextuserScript = window.nextuser;
    if (!NextuserScript) {
      return;
    }
    NextuserScript.track.apply(null, [`${categoryPrefix}_${eventNameNu}`, ...params]);
  } catch (error) {}
};

export default nextuser;
