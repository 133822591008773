import { useTranslation } from "react-i18next";

import "./styles.scss";

interface IFieldValue {
  value: string;
}

const FieldValue = ({ value }: IFieldValue) => {
  const [t] = useTranslation();
  return <div className="FieldValue">{t(value)}</div>;
};

export default FieldValue;
