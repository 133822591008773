import { useContext } from "react";
import { useTranslation } from "react-i18next";
import AppContext from "../../context/AppContext";
import { StepItem } from "./components";

import "./styles.scss";

interface StepContainerProps {
  activeStep: number;
  onStepClick: (step: number) => void;
}

interface StepDefinition {
  id: number;
  titleKey: string;
}

const StepDefinitions: StepDefinition[] = [
  {
    id: 0,
    titleKey: "stepContainer.homeInfo",
  },
  {
    id: 1,
    titleKey: "stepContainer.functionDetails",
  },
  {
    id: 2,
    titleKey: "stepContainer.recommendedSolutions",
  },
];

const StepContainer = function ({
  activeStep,
  onStepClick,
}: StepContainerProps) {
  const { t } = useTranslation();
  const appState = useContext(AppContext);

  const getTitle = (sd: StepDefinition, index: number): string => {
    if (activeStep !== 1 || index !== 1) {
      return t(sd.titleKey);
    }

    return `${t(sd.titleKey)} - ${t(
      "homeContainer.deviceType." + appState.activeDeviceType
    )}`; //step t
  };

  return (
    <div className="Stepper">
      {StepDefinitions.map((sd, index) => (
        <StepItem
          key={`step_item_${index}`}
          isActive={sd.id <= activeStep}
          id={sd.id}
          title={getTitle(sd, index)}
          onClick={onStepClick}
        />
      ))}
    </div>
  );
};

export default StepContainer;
