import React, { FC, useEffect } from "react";
import { gaActions } from "../../../../../../utils/constants";
import { trackEvents } from "../../../../../../utils/tracking/tracking";
import "./styles.scss";

interface Props {
  step: number;
  activeDeviceType: string;
  answer: string;
  handleAnswer: (option: string) => void;
  options: string[];
  isDetailsActive: boolean;
}

// const handleGAEvent = function (title: string, option: string) {
const handleGAEvent = function (activeDeviceType: string) {
  if (activeDeviceType === "Heat") {
    activeDeviceType = "Heating";
  }
  trackEvents({
    action: `click_${activeDeviceType}`,
    label: "step_complete",
    nonInteraction: false,
    nuEventName: `click_${activeDeviceType}_step_complete`,
  });
};

const SimpleSelect: FC<Props> = ({
  answer,
  handleAnswer,
  options,
  step,
  activeDeviceType,
}) => {
  useEffect(() => {
    trackEvents({
      action: `${gaActions.display}_${activeDeviceType}`,
      label: "step_display",
      nuEventName: `${gaActions.display}_${activeDeviceType}_step_display`,
    });
  }, []);
  return (
    <div className="SimpleSelect">
      {options.map((option, i) => {
        return (
          <div
            key={`simple_select_option_${i}`}
            className={`SimpleSelect__Option ${
              option === answer ? "SimpleSelect__Option--Active" : ""
            }`}
            onClick={() => {
              handleAnswer(option);
              handleGAEvent(activeDeviceType);
            }}
          >
            {option}
          </div>
        );
      })}
    </div>
  );
};

export default SimpleSelect;
