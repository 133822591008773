const SelectedProduct = ({
  quantity,
  product,
  onDecrease,
  onIncrease,
}: any) => {
  return (
    <div className="BOM__Product">
      <div className="BOM__Product__Image">
        <img alt={product.title} src={product.imageUrl} />
      </div>
      <div className="BOM__Product__Title">{product.title || "N/A"}</div>
      <div className="BOM__Product__Reference">{product.id}</div>
      <div className="BOM__Product__Description">
        {product.description || "N/A"}
      </div>
      <div className="BOM__Product__Quantity">
        <button
          onClick={onDecrease}
          type="button"
          className="BOM__Product__Quantity__Decrease"
        >
          -
        </button>
        <div className="BOM__Product__Quantity__Value">{quantity}</div>
        <button
          onClick={onIncrease}
          type="button"
          className="BOM__Product__Quantity__Increase"
        >
          +
        </button>
      </div>
    </div>
  );
};

export default SelectedProduct;
