import React, { FC, ReactNode } from "react";
import "./styles.scss";

interface CardProps {
  disabled?: boolean;
  subtitle?: string;
  title: string | ReactNode;
  className?: string;
  visible?: boolean;
}

const Card: FC<CardProps> = ({
  children,
  disabled,
  title,
  subtitle,
  className: customClassName,
  visible: isVisible = true,
}) => {
  return (
    <div
      className={`Card ${disabled ? "Card-disabled" : ""} ${
        customClassName || ""
      } ${!isVisible ? "Card--hidden" : ""} `}
    >
      {!!title && <h4 className="Card-title">{title}</h4>}
      {!!subtitle && <h6 className="Card-subtitle">{subtitle}</h6>}
      <div className="Card-content">{children}</div>
    </div>
  );
};

export default Card;
