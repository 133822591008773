import { useTranslation } from "react-i18next";
import { RoomListDefinition } from "../../types";

import { CustomLayoutRoomItem } from "./components/";
import "./styles.scss";

interface CustomLayoutSelectionProps {
  roomState: RoomListDefinition | null;
  onReset: () => void;
}

const CustomLayoutSelection = ({
  roomState,
  onReset,
}: CustomLayoutSelectionProps) => {
  const [t] = useTranslation();
  return (
    <div className="CustomLayoutSelection">
      <div className="CustomLayoutSelection__Controls">
        <button className="CustomLayoutSelection__Button" onClick={onReset}>{t("general.reset")}</button>
      </div>
      <div className="CustomLayoutSelection__Selection">
        <CustomLayoutRoomItem
          label={t("shared.roomTypes.livingRoom")}
          count={roomState?.livingRoom || 0}
        />
        <CustomLayoutRoomItem
          label={t("shared.roomTypes.bathroom")}
          count={roomState?.bathrooms || 0}
        />
        <CustomLayoutRoomItem
          label={t("shared.roomTypes.cuisines")}
          count={roomState?.cuisines || 0}
        />
        <CustomLayoutRoomItem
          label={t("shared.roomTypes.toilet")}
          count={roomState?.toilet || 0}
        />
        <CustomLayoutRoomItem
          label={t("shared.roomTypes.bedroom")}
          count={roomState?.bedrooms || 0}
        />
        <CustomLayoutRoomItem
          label={t("shared.roomTypes.office")}
          count={roomState?.office || 0}
        />
        <CustomLayoutRoomItem
          label={t("shared.roomTypes.outside")}
          count={roomState?.outside || 0}
        />
      </div>
    </div>
  );
};

export default CustomLayoutSelection;
