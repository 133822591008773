const targetOrigin = "https://www.materielelectrique.com";

export const postMsgToParent = (eventType: string, payloadData?: object) => {
  const payloadObj = {
    eventType: eventType,
    payload: payloadData || {},
  };

  try {
    window.top?.postMessage(JSON.stringify(payloadObj), targetOrigin);
  } catch (error) {}
};
