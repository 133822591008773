import { useState, useReducer, useEffect, useRef } from "react";
import { trackEvents } from "./utils/tracking/tracking";
import "./App.scss";
import "./shared/styles/buttons.scss";
import { gaActions } from "./utils/constants";
import {
  AppHeader,
  Stepper,
  HomeContainer,
  QuizContainer,
  BOMContainer,
} from "./components";

import AppContext from "./context/AppContext";
import appReducer, { ActionKind } from "./context/reducer";
import { DeviceTypes } from "./shared/types";
import { RoomListDefinition } from "./components/HomeContainer/components/PropertyLayoutStep/types";

function usePrevious(value: any) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

function App() {
  const [activeStep, setStep] = useState<number>(0);

  const previousStep = usePrevious(activeStep);

  const [appState, dispatch] = useReducer(appReducer, {
    deviceTypes: [],
    results: [],
    layoutType: null,
    propertyType: "",
    activeDeviceType: "",
    questions: [],
    currentQuestionNumber: 0,
    finished: false,
    homeContainerStep: 0,
    customLayoutSelected: false,
  });

  const setNextActiveDeviceType = (deviceType: DeviceTypes | undefined) => {
    dispatch({
      type: ActionKind.SetNextActiveDevice,
      payload: deviceType,
    });
  };

  const setDeviceTypes = (deviceTypes: DeviceTypes[]) => {
    dispatch({
      type: ActionKind.SetDeviceTypes,
      payload: deviceTypes,
    });
  };

  const pushSelection = (result: any) => {
    dispatch({
      type: ActionKind.PushResult,
      payload: result,
    });
  };

  const setPropertType = (type: any) => {
    dispatch({
      type: ActionKind.SetPropertType,
      payload: type,
    });
  };

  const setLayouType = (type: RoomListDefinition) => {
    dispatch({
      type: ActionKind.SetLayoutType,
      payload: type,
    });
  };

  const setCustomLayoutSelected = (isOpen: Boolean) => {
    dispatch({
      type: ActionKind.SetHomeContainerCustomLayoutSelected,
      payload: isOpen,
    });
  };

  const popDeviceType = () => {
    if (appState.deviceTypes.length === 0) {
      return;
    }

    const deviceType = appState.deviceTypes.pop();
    setNextActiveDeviceType(deviceType);
    setDeviceTypes(appState.deviceTypes);
    // dispatch({
    //   type: ActionKind.SetNextActiveDevice,
    //   payload: deviceType,
    // });
  };

  const addQuestion = (question: any) => {
    dispatch({
      type: ActionKind.AddQuestion,
      payload: question,
    });
  };

  const setQuestionAnswered = (questionValue: string) => (answer: string) => {
    dispatch({
      type: ActionKind.SetQuestionAnswered,
      payload: { questionValue, answer },
    });
  };

  const popQuestion = () => {
    dispatch({
      type: ActionKind.PopQuestion,
      payload: appState.questions.slice(0, -1),
    });
  };

  const setHomeContainerStep = (step: number) => {
    dispatch({
      type: ActionKind.SetHomeContainerStep,
      payload: step,
    });
  };

  const popResult = () => {
    const { results, deviceTypes, activeDeviceType } = appState;

    const lastResponse = results.pop();

    const newDeviceTypes = deviceTypes;
    if (
      newDeviceTypes.indexOf(activeDeviceType) < 0 &&
      activeDeviceType !== lastResponse.deviceType
    ) {
      newDeviceTypes.push(activeDeviceType);
    }

    dispatch({
      type: ActionKind.PopResult,
      payload: {
        deviceTypes: [...newDeviceTypes],
        results: [...results],
        activeDeviceType: lastResponse.deviceType,
        finished: lastResponse.finished,
        questions: lastResponse.questions,
        currentQuestionNumber: lastResponse.currentQuestionNumber,
      },
    });
  };

  const handleBackFromBOM = () => {
    setStep(1);
    popResult();
  };

  const displayQuizContainer = () => {
    setStep(1);
    const firstDeviceType = appState.deviceTypes.pop();
    setNextActiveDeviceType(firstDeviceType);
  };

  const handleBackFromQuiz = () => {
    setStep(0);
    dispatch({
      type: ActionKind.NavigateHome,
      payload: {
        deviceTypes: [...appState.deviceTypes, appState.activeDeviceType],
      },
    });
  };

  const resetState = () => {
    trackEvents({
      action: gaActions.clickSummary,
      label: "reset",
      nonInteraction: false,
      nuEventName: `${gaActions.clickSummary}_reset`,
    });
    dispatch({
      type: ActionKind.ResetState,
    });
    setStep(0);
  };

  const handleStepClick = (step: number) => {
    if (step >= activeStep) {
      return;
    } else if (step === 1) {
      dispatch({
        type: ActionKind.NavigateQuiz,
      });
    } else if (step === 0) {
      dispatch({
        type: ActionKind.NavigateHome,
      });
    }

    setStep(step);
  };

  return (
    <div className="App">
      <AppContext.Provider value={appState}>
        <AppHeader />
        <Stepper activeStep={activeStep} onStepClick={handleStepClick} />
        {activeStep === 0 && (
          <HomeContainer
            key={`active_step_${activeStep}_home_container`}
            step={activeStep}
            customLayoutSelected={appState.customLayoutSelected}
            setCustomLayoutSelected={setCustomLayoutSelected}
            homeContainerStep={appState.homeContainerStep}
            setHomeContainerStep={setHomeContainerStep}
            deviceTypes={appState.deviceTypes}
            propertyType={appState.propertyType}
            layoutType={appState.layoutType}
            setNextStep={displayQuizContainer}
            setPreviousStep={() => setStep(activeStep - 1)}
            setDeviceTypes={setDeviceTypes}
            setPropertType={setPropertType}
            setLayoutType={setLayouType}
          />
        )}
        {activeStep === 1 && (
          <QuizContainer
            key={`active_step_${activeStep}_quiz_container`}
            step={activeStep}
            previousStep={previousStep}
            setQuestionAnswered={setQuestionAnswered}
            setNextStep={() => setStep(2)}
            quizFlow={appState.quizFlow}
            setPreviousStep={handleBackFromQuiz}
            popQuestion={popQuestion}
            finished={appState.finished}
            deviceTypes={appState.deviceTypes}
            setDeviceTypes={setDeviceTypes}
            pushSelection={pushSelection}
            setNextActiveDeviceType={setNextActiveDeviceType}
            questions={appState.questions}
            currentQuestionNumber={appState.currentQuestionNumber}
            activeDeviceType={appState.activeDeviceType}
            popDeviceType={popDeviceType}
            addQuestion={addQuestion}
            results={appState.results}
            popResult={popResult}
          />
        )}
        {activeStep === 2 && (
          <BOMContainer
            key={`active_step_${activeStep}_bom_container`}
            step={activeStep}
            resetState={resetState}
            setNextStep={() => setStep(activeStep + 1)}
            setPreviousStep={handleBackFromBOM}
            results={appState.results}
            previousStep={previousStep}
          />
        )}
      </AppContext.Provider>
    </div>
  );
}

export default App;
