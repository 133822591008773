import React, { FC } from 'react'
import Icon, { IconProps } from './Icon'

const Info: FC<IconProps> = (props) => (
  <Icon height={24} width={24} fill="#000" viewBox="0 0 16.875 16.875" {...props} >
    <g transform="translate(0 16.875)">
      <path d="M9.844-5.142A5.489,5.489,0,0,1,8.328-3.823a3.685,3.685,0,0,1-1.955.615.715.715,0,0,1-.505-.242.662.662,0,0,1-.242-.417,1.992,1.992,0,0,1,.22-.7L7.559-8.7q.088-.22-.044-.22a1.813,1.813,0,0,0-.7.352,5.345,5.345,0,0,0-.791.615A.423.423,0,0,1,5.8-8.13a.219.219,0,0,1-.044-.22,5.958,5.958,0,0,1,1.67-1.362,3.626,3.626,0,0,1,1.67-.571.715.715,0,0,1,.505.242.715.715,0,0,1,.242.505,2.877,2.877,0,0,1-.176.7L7.91-4.746q-.088.22.088.22a1.194,1.194,0,0,0,.4-.176,6.758,6.758,0,0,0,.637-.418,4.5,4.5,0,0,0,.505-.418q.352-.044.352.308A.127.127,0,0,1,9.844-5.142Zm.044-5.845a1.265,1.265,0,0,1-.923-.4,1.29,1.29,0,0,1-.4-.945,1.29,1.29,0,0,1,.4-.945,1.29,1.29,0,0,1,.945-.4,1.289,1.289,0,0,1,.945.4,1.289,1.289,0,0,1,.4.945,1.289,1.289,0,0,1-.4.945A1.315,1.315,0,0,1,9.888-10.986Zm3.823-2.725a7.192,7.192,0,0,0-5.273-2.2,7.192,7.192,0,0,0-5.273,2.2,7.192,7.192,0,0,0-2.2,5.273,7.192,7.192,0,0,0,2.2,5.273,7.192,7.192,0,0,0,5.273,2.2,7.192,7.192,0,0,0,5.273-2.2,7.192,7.192,0,0,0,2.2-5.273A7.192,7.192,0,0,0,13.711-13.711ZM2.483-14.392a8.122,8.122,0,0,1,5.955-2.483,8.122,8.122,0,0,1,5.955,2.483,8.122,8.122,0,0,1,2.483,5.955,8.122,8.122,0,0,1-2.483,5.955A8.122,8.122,0,0,1,8.438,0,8.122,8.122,0,0,1,2.483-2.483,8.122,8.122,0,0,1,0-8.437,8.122,8.122,0,0,1,2.483-14.392Z" />
    </g>
  </Icon>
)

export default Info

