import "./styles.scss";

interface IQuestionDetailsProps {
  text?: string;
  imagePath?: string[];
}

const QuestionDetails = ({ text, imagePath }: IQuestionDetailsProps) => {
  return (
    <div className="QuestionDetails">
      <div className="QuestionDetails__ImageContainer">
        {imagePath &&
          imagePath.map((path) => (
            <img
              key={`image-${path}`}
              className="QuestionDetails__Image"
              alt={text}
              src={path}
            />
          ))}
      </div>
      {text && <div className="QuestionDetails__Description">{text}</div>}
    </div>
  );
};

export default QuestionDetails;
