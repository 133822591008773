import React, { FC } from "react";
import Icon, { IconProps } from "./Icon";

const ChevronLeft: FC<IconProps> = (props) => (
  <Icon {...props} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2082 2.63588C11.8355 2.03323 11.8355 1.02976 11.2082 0.427104C10.6157 -0.142183 9.67943 -0.142394 9.08663 0.426626L0.615028 8.55833C-0.204753 9.34522 -0.205049 10.6562 0.614378 11.4434L9.13892 19.6333C9.73153 20.2027 10.6679 20.2027 11.2605 19.6333C11.8877 19.0308 11.8877 18.0276 11.2605 17.425L3.53765 10.0053L11.2082 2.63588Z"
      transform="translate(5.88745 1.96946)"
    />
  </Icon>
);

export default ChevronLeft;
