import { FC } from 'react';
import Icon, { IconProps } from './Icon';

const Bed: FC<IconProps> = (props) => (
  <Icon height={24} width={24} fill="#000" viewBox="0 0 24 16.875" {...props}>
    <g id="Group_461" data-name="Group 461" transform="translate(0 16.875)">
      <path
        id="Path_42"
        data-name="Path 42"
        d="M3.625,0h-3Q0,0,0-.687v-15.5q0-.687.625-.687h3a.608.608,0,0,1,.688.688v5.875H7.625A2.206,2.206,0,0,0,7-11.75a1.4,1.4,0,0,0-1.125-.437.6.6,0,0,1-.437-.187.676.676,0,0,1-.187-.5.709.709,0,0,1,.188-.469.562.562,0,0,1,.438-.219A2.765,2.765,0,0,1,8-12.687,4.4,4.4,0,0,1,9-9.562a.618.618,0,0,1-.687.625H3.625Q3-8.937,3-9.625v-5.937H1.375v14.25H3v-2.75q0-.687.625-.687h16.75q.625,0,.625.688v2.688h1.625v-6a1.075,1.075,0,0,0-.25-.687,1.7,1.7,0,0,0-.562-.469,3.56,3.56,0,0,0-.656-.25q-.344-.094-.531-.125a1.92,1.92,0,0,0-.25-.031H11.063v2.125q0,.625-.687.625a.552.552,0,0,1-.625-.625V-9.625a.709.709,0,0,1,.188-.469.562.562,0,0,1,.438-.219H20.5q3.188.563,3.5,2.875v6.75a.6.6,0,0,1-.187.438.6.6,0,0,1-.437.188h-3a.788.788,0,0,1-.531-.187.562.562,0,0,1-.219-.437v-2.75H4.375v2.75Q4.375,0,3.625,0Z"
        fill="#fff"
      />
    </g>
  </Icon>
);

export default Bed;
