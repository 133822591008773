import { useState } from "react";
import Card from "../../../Card";
import SimpleSelect from "./components/SimpleSelect";
import TileSelect from "./components/TileSelect";
import IconSelect from "./components/IconSelect";
import { QuestionDetails } from "./components/";
import { QuestionDefinition, QuestionType } from "../../../../shared/types";
import { trackEvents } from "../../../../utils/tracking/tracking";
import "./styles.scss";
import { Info } from "../../../icons";

interface QuestionProps {
  step: number;
  activeDeviceType: string;
  type?: QuestionType;
  title: string;
  options: string[];
  disabled: boolean;
  onAnswer: (answer: string) => void;
  definition: QuestionDefinition;
  answer?: string;
  visible?: boolean;
}

const getQuestionComponentByType = (type?: QuestionType) => {
  switch (type as QuestionType) {
    case QuestionType.TILE:
      return TileSelect;
    case QuestionType.ICON:
      return IconSelect;
    default:
      return SimpleSelect;
  }
};

const Question = ({
  step,
  activeDeviceType,
  title,
  type,
  options,
  disabled,
  definition,
  answer,
  onAnswer,
  visible,
}: QuestionProps) => {
  const [selectedAnswer, setSelectedAnswer] = useState(answer || "");
  const [isDetailsActive, setDetailsActive] = useState(false);

  const handleAnswer = (option: string) => {
    setSelectedAnswer(option);
    onAnswer(option);
  };

  const QuestionComponent = getQuestionComponentByType(
    definition?.question_type
  );

  const isQuestionsDetailsText =
    typeof definition?.question_details_text === typeof "";

  const handleGAEvent = function (activeDeviceType: string) {
    trackEvents({
      action: `click_${activeDeviceType}`,
      label: "information",
      nonInteraction: false,
      nuEventName: `click_${activeDeviceType}_information`,
    });
  };

  return (
    <Card
      visible={visible}
      disabled={disabled}
      title={
        <>
          {title}
          {definition?.question_details === "yes" && (
            <button
              className="Button Button--Details"
              onClick={() => {
                setDetailsActive(!isDetailsActive);
                handleGAEvent(activeDeviceType);
              }}
            >
              <Info />
            </button>
          )}
        </>
      }
    >
      <div
        className={`Question ${
          isQuestionsDetailsText && isDetailsActive ? " Question--Flipped" : ""
        }`}
      >
        <QuestionComponent
          step={step}
          activeDeviceType={activeDeviceType}
          answer={selectedAnswer}
          options={options}
          handleAnswer={handleAnswer}
          definition={definition}
          isDetailsActive={isDetailsActive}
        />
        {isQuestionsDetailsText && (
          <QuestionDetails
            imagePath={definition?.question_details_image}
            text={definition?.question_details_text}
          />
        )}
      </div>
    </Card>
  );
};

export default Question;
