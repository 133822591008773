import React, { FC } from 'react'
import './styles.scss'

const GlobalLoader: FC = () => (
  <div className="GlobalLoader">
    <div className="lds-ripple">
      <div/>
      <div/>
    </div>
  </div>
)

export default GlobalLoader
