import { useTranslation } from "react-i18next";

import './styles.scss';

interface IFieldLabel {
  value: string;
}



const FieldLabel = ({ value }: IFieldLabel) => {
  const [t] = useTranslation();
  return <div className="FieldLabel">{t(value)}</div>;
};

export default FieldLabel;
