import { RoomListDefinition } from "../components/HomeContainer/components/PropertyLayoutStep/types";

export enum DeviceTypes {
  LIGHTNING = "Lighting",
  HEATING = "Heat",
  SHUTTER = "Shutter",
}

export interface IResult {
  type?: string;
  deviceType?: DeviceTypes;
  answeredQuestions: any[];
}
export interface ConfiguratorState {
  deviceTypes: DeviceTypes[];
  results: IResult[];
  layoutType?: RoomListDefinition;
  propertyType: string;
  activeDeviceType: string;
  questions: any[];
  currentQuestionNumber: number;
  finished: boolean;
  toggleDeviceType: (deviceType: string) => void;
  pushSelection: () => void;
}

export interface IBrandDefinition {
  deviceType: DeviceTypes;
  resultsByBrand: any;
  selectedBrand: string;
}

export type Action<T> = {
  type: T;
  payload?: string | DeviceTypes[] | any | DeviceTypes | RoomListDefinition;
};

export interface IProduct {
  id: string;
  imageUrl: string;
  description: string;
  price: string;
  quantity: number;
  title?: string;
  totalPrice: string;
  url: string;
}

export interface QuestionDefinition {
  answerCount?: number;
  icons?: any;
  questionValue: string;
  question_details: "no" | "yes";
  question_details_image?: string[];
  question_details_text?: string | any;
  question_type: QuestionType;
  tile_image: any;
  tile_type: "horizontal" | "vertical";
}

export enum QuestionType {
  SIMPLE_SELECT = "SIMPLE_SELECT",
  TILE = "tile",
  ICON = "icon",
}

export enum QuizFlow {
  Forward = "FORWARD",
  Backward = "BACKWARD",
}
