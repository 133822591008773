import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { RoomListTypes } from '../../../../../../models';
import { Bed } from '../../../../../../components/icons';
import { RoomListDefinition } from '../../types';

import './styles.scss';

interface RoomListItemProps extends RoomListDefinition {
  mode: RoomListTypes;
  onSelect: () => void;
  selected: boolean;
}

const RoomListItem: FC<RoomListItemProps> = ({
  title,
  subtitle,
  mode,
  bedrooms,
  bathrooms,
  cuisines,
  toilet,
  livingRoom,
  homeSolution,
  onSelect,
  selected,
}) => {
  const [t] = useTranslation();
  return (
    <div
      className={`RoomListItem ${
        mode === RoomListTypes.DEFAULT
          ? 'RoomListItem--Default'
          : 'RoomListItem--Details'
      } ${selected ? 'RoomListItem--Selected' : ''}`}
      onClick={() => onSelect()}
    >
      {mode === RoomListTypes.DEFAULT && (
        <>
          <div className="RoomListItemContent ">
            <div className="RoomListItemTitle">{t(title)}</div>
            <div className="RoomListItemSubTitle">{t(subtitle)}</div>
          </div>
          <div className="RoomListItemRoomCount">
            <div>
              <Bed width={35} height={35} />
            </div>
            <div className="RoomListItemRoomCountIndicator">{`x ${bedrooms}`}</div>
          </div>
        </>
      )}
      {mode === RoomListTypes.DETAILS && (
        <>
          <div className="RoomListItemContent ">
            <div className="RoomListItemType">{t(title)}-</div>
            <div className="RoomListItemRoomDetails">
              {`
                ${
                  bedrooms > 0
                    ? `${bedrooms} ${t('shared.roomTypes.bedroom')}, `
                    : ''
                }
                ${bathrooms} ${t('shared.roomTypes.bathroom')},
                ${cuisines} ${t('shared.roomTypes.cuisines')},
                ${toilet} ${t('shared.roomTypes.toilet')},
                ${livingRoom} ${t('shared.roomTypes.livingRoom')},
                ${homeSolution} ${t('shared.roomTypes.homeSolution')}
              `}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default RoomListItem;
