import React, { FC, useCallback, useState } from "react";
import { trackEvents } from "../../../../utils/tracking/tracking";
import { useTranslation } from "react-i18next";
import { gaActions } from "../../../../utils/constants";
import { RoomListItem, RoomModal, CustomLayoutSelection } from "./components";
import { Card } from "../../..";
import { RoomListTypes } from "../../../../models";
import { House, Info } from "../../../icons";
import { RoomListDefinition } from "./types";
import "./styles.scss";

interface PropertyLayoutStepProps {
  disabled: boolean;
  onSelect: (rld: RoomListDefinition) => void;
  layoutType: RoomListDefinition;
  customLayoutSelected: Boolean;
  setCustomLayoutSelected: (isOpen: Boolean) => void;
}

const RoomListDefinitions: RoomListDefinition[] = [
  {
    title: "T1",
    subtitle: "type",
    bathrooms: 1,
    cuisines: 1,
    toilet: 1,
    livingRoom: 1,
    bedrooms: 0,
    homeSolution: 1,
    outside: 0,
    kitchen: 0,
    office: 0,
  },
  {
    title: "T2",
    subtitle: "type",
    bathrooms: 1,
    cuisines: 1,
    toilet: 1,
    livingRoom: 1,
    bedrooms: 1,
    homeSolution: 1,
    outside: 0,
    kitchen: 0,
    office: 0,
  },
  {
    title: "T3",
    subtitle: "type",
    bathrooms: 1,
    cuisines: 1,
    toilet: 1,
    livingRoom: 1,
    bedrooms: 2,
    homeSolution: 1,
    outside: 0,
    kitchen: 0,
    office: 0,
  },
  {
    title: "T4",
    subtitle: "type",
    bathrooms: 1,
    cuisines: 1,
    toilet: 1,
    livingRoom: 1,
    bedrooms: 3,
    homeSolution: 1,
    outside: 0,
    kitchen: 0,
    office: 0,
  },
];

const PropertyLayoutStep: FC<PropertyLayoutStepProps> = ({
  disabled,
  onSelect,
  layoutType,
  customLayoutSelected: isCustomLayoutSelected,
  setCustomLayoutSelected,
}) => {
  const [t] = useTranslation();
  const [isLayoutModalOpen, setLayoutModalState] = useState(false);
  // const [isCustomLayoutSelected, setCustomLayoutSelected] = useState(false);
  const [selectedRooms, setSelectedRooms] = useState<RoomListDefinition | null>(
    layoutType
  );
  const [cardPropertyLayoutMode, setCardPropertyLayoutMode] = useState(
    RoomListTypes.DEFAULT
  );

  const [selectedLayout, setSelectedLayout] =
    useState<RoomListDefinition>(layoutType);

  const handleGAEvent = function () {
    trackEvents({
      action: `${gaActions.clickPropertyType}_${gaActions.chooseOwnLayout}`,
      nonInteraction: false,
      nuEventName: `${gaActions.clickPropertyType}_${gaActions.chooseOwnLayout}`,
    });
  };

  const handleLayoutToggleClick = useCallback(() => {
    trackEvents({
      action: gaActions.clickPropertyLayout,
      label: "info",
      nonInteraction: false,
      nuEventName: `${gaActions.clickPropertyLayout}_info`,
    });
    setCardPropertyLayoutMode(
      cardPropertyLayoutMode === RoomListTypes.DEFAULT
        ? RoomListTypes.DETAILS
        : RoomListTypes.DEFAULT
    );
  }, [cardPropertyLayoutMode]);

  const handleModalConfirmation = (roomState: any) => {
    trackEvents({
      action: `${gaActions.clickPropertyLayout}_${gaActions.chooseOwnLayout}`,
      label: "validate",
      nonInteraction: false,
      nuEventName: `${gaActions.clickPropertyLayout}_${gaActions.chooseOwnLayout}_validate`,
    });
    setLayoutModalState(false);
    setCustomLayoutSelected(true);
    setSelectedRooms(roomState);
    onSelect(roomState);
  };

  const onCustomLayoutReset = () => {
    trackEvents({
      action: `${gaActions.clickPropertyLayout}_${gaActions.chooseOwnLayout}`,
      label: "reset",
      nonInteraction: false,
      nuEventName: `${gaActions.clickPropertyLayout}_${gaActions.chooseOwnLayout}_reset`,
    });
    setSelectedRooms(null);
    setCustomLayoutSelected(false);
    setLayoutModalState(false);
  };

  return (
    <>
      <Card
        disabled={disabled}
        className="layoutType"
        title={
          <>
            {t("homeContainer.choosePropertyLayout")}
            <button
              className="Button Button--Details"
              onClick={handleLayoutToggleClick}
            >
              <Info />
            </button>
          </>
        }
      >
        <div className="card-list-container ">
          {isCustomLayoutSelected && (
            <CustomLayoutSelection
              onReset={onCustomLayoutReset}
              roomState={selectedRooms}
            />
          )}
          {!isCustomLayoutSelected && (
            <div className="RoomListDefinitions">
              {RoomListDefinitions.map((rld) => (
                <RoomListItem
                  selected={selectedLayout?.title === rld.title}
                  onSelect={() => {
                    setSelectedLayout(rld);
                    onSelect(rld);
                  }}
                  key={rld.title}
                  mode={cardPropertyLayoutMode}
                  {...rld}
                />
              ))}
            </div>
          )}
          <div className="card-controls">
            <div>{t("homeContainer.propertyLayoutNumberOfRooms")}</div>
            <button
              className={`Button withIcon ${
                isCustomLayoutSelected ? "Button--Activated" : ""
              } `}
              type="button"
              onClick={() => {
                setLayoutModalState(true);
                handleGAEvent();
              }}
            >
              <House />
              <span>{t("homeContainer.propertyLayoutOwnLayout")}</span>
            </button>
          </div>
        </div>
      </Card>
      <RoomModal
        isOpen={isLayoutModalOpen}
        onRequestClose={() => setLayoutModalState(false)}
        onValidate={handleModalConfirmation}
      />
    </>
  );
};

export default PropertyLayoutStep;
