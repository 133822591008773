import {
  Action,
  ConfiguratorState,
  DeviceTypes,
  QuizFlow,
} from "../shared/types";

const distinctFilter = (value: any, index: number, self: any) => {
  return self.indexOf(value) === index;
};

export enum ActionKind {
  SetDeviceTypes = "SET_DEVICE_TYPES",
  PushResult = "PUSH_RESULTS",
  SetNextActiveDevice = "SET_NEXT_ACTIVE_DEVICE",
  SetPropertType = "SET_PROPERTY_TYPE",
  SetLayoutType = "SET_LAYOUT_TYPE",
  AddQuestion = "ADD_QUESTION",
  SetQuestionAnswered = "SET_QUESTION_ANSWERED",
  PopResult = "POP_RESULT",
  SetQuestions = "SET_QUESTIONS",
  NavigateHome = "NAVIGATE_HOME",
  NavigateQuiz = "NAVIGATE_QUIZ",
  ResetState = "RESET_STATE",
  SetHomeContainerStep = "SET_HOME_CONTAINER_STEP",
  NavigateBack = "NAVIGATE_BACK",
  PopQuestion = "POP_QUESTION",
  SetHomeContainerCustomLayoutSelected = "SET_HOME_CONTAINER_CUSTOM_LAYOUT_SELECTED",
}

const reducer = (state: ConfiguratorState, action: Action<ActionKind>) => {
  switch (action.type) {
    case ActionKind.SetDeviceTypes:
      return { ...state, deviceTypes: action.payload };

    case ActionKind.PushResult:
      const { deviceType } = action.payload;
      const results = state.results;
      const resultIndex = results.findIndex((x) => x.deviceType === deviceType);
      if (resultIndex >= 0) {
        results.splice(resultIndex, 1);
      }
      return { ...state, results: [...state.results, action.payload] };
    case ActionKind.ResetState:
      return {
        ...state,
        results: [],
        questions: [],
        deviceTypes: [],
        customLayoutSelected: false,
        activeDeviceType: "",
        homeContainerStep: 0,
        currentQuestionNumber: 0,
        propertyType: "",
        layoutType: null,
        quizFlow: QuizFlow.Forward,
      };
    case ActionKind.NavigateQuiz:
      return {
        ...state,
        quizFlow: QuizFlow.Backward,
      };
    case ActionKind.PopResult:
      return { ...state, ...action.payload, quizFlow: QuizFlow.Backward };
    case ActionKind.AddQuestion:
      return {
        ...state,
        currentQuestionNumber: state.currentQuestionNumber + 1,
        finished: false,
        questions: [...state.questions, action.payload],
        quizFlow: QuizFlow.Forward,
      };
    case ActionKind.NavigateHome:
      let deviceTypes: DeviceTypes[] = [
        ...state.results.map((r) => r.deviceType as DeviceTypes),
        ...state.deviceTypes,
        state.activeDeviceType as DeviceTypes,
      ].filter(distinctFilter);

      return {
        ...state,
        questions: [],
        results: [],
        deviceTypes,
        finished: false,
        activeDeviceType: "",
        currentQuestionNumber: 0,
      };
    case ActionKind.SetQuestions:
      return {
        ...state,
        currentQuestionNumber: action.payload.length - 1,
        questions: action.payload,
      };
    case ActionKind.SetQuestionAnswered:
      const index = state.questions.findIndex(
        (q: any) => q.questionValue === action.payload.questionValue
      );
      state.questions[index].answer = action.payload.answer;
      state.questions.splice(index + 1);
      // reset before set
      return { ...state, quizFlow: QuizFlow.Forward };
    case ActionKind.SetLayoutType:
      return { ...state, layoutType: action.payload };
    case ActionKind.SetPropertType:
      return { ...state, propertyType: action.payload };
    case ActionKind.SetHomeContainerStep:
      return { ...state, homeContainerStep: action.payload };
    case ActionKind.PopQuestion:
      return {
        ...state,
        currentQuestionNumber: action.payload.length - 1,
        questions: action.payload,
        quizFlow: QuizFlow.Backward,
      };
    case ActionKind.SetNextActiveDevice:
      return {
        ...state,
        activeDeviceType: action.payload,
        questions: [],
        currentQuestionNumber: 0,
      };
    case ActionKind.SetHomeContainerCustomLayoutSelected:
      return {
        ...state,
        customLayoutSelected: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
