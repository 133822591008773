import { useTranslation } from 'react-i18next';

import './styles.scss';

interface QuantityInputProps {
  quantity: number;
  onIncrease(key: string): void;
  onDecrease(key: string): void;
  roomKey: string;
}

const QuantityInput = ({
  roomKey,
  quantity,
  onIncrease,
  onDecrease,
}: QuantityInputProps) => {
  const [t] = useTranslation();

  const handleIncrease = (e: React.MouseEvent<HTMLButtonElement>): void => {
    onIncrease(roomKey);
  };

  const handleDecrease = (e: React.MouseEvent<HTMLButtonElement>): void => {
    onDecrease(roomKey);
  };

  return (
    <div className="QuantityInput">
      <div className="QuantityInput__Label">
        {t('shared.quantityInput.label')}
      </div>
      <div className="QuantityInput__Controls">
        <button
          onClick={handleDecrease}
          className="QuantityInput__Controls__Btn QuantityInput__Controls__Btn--decrease"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="21"
            viewBox="0 0 21 21"
          >
            <g
              id="Group_467"
              data-name="Group 467"
              transform="translate(-8.526 21)"
            >
              <path
                id="Path_44"
                data-name="Path 44"
                d="M11.616-17.91A10.107,10.107,0,0,1,19.026-21a10.107,10.107,0,0,1,7.41,3.09,10.107,10.107,0,0,1,3.09,7.41,10.107,10.107,0,0,1-3.09,7.41A10.107,10.107,0,0,1,19.026,0a10.107,10.107,0,0,1-7.41-3.09,10.107,10.107,0,0,1-3.09-7.41A10.107,10.107,0,0,1,11.616-17.91ZM24.659-9.734a.953.953,0,0,0,.492-.273.742.742,0,0,0,.219-.547.637.637,0,0,0-.711-.656H13.393a.584.584,0,0,0-.519.164.673.673,0,0,0-.246.52.763.763,0,0,0,.191.519.85.85,0,0,0,.519.273Z"
                fill="#009643"
              />
            </g>
          </svg>
        </button>
        <div className="QuantityInput__Controls__Input">{quantity}</div>
        <button
          onClick={handleIncrease}
          className="QuantityInput__Controls__Btn QuantityInput__Controls__Btn--increase"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="21"
            viewBox="0 0 21 21"
          >
            <path
              id="Path_45"
              data-name="Path 45"
              d="M10.5-68.5a10.081,10.081,0,0,1,7.438,3.09A10.184,10.184,0,0,1,21-57.975a10.049,10.049,0,0,1-3.09,7.41A10.152,10.152,0,0,1,10.5-47.5a10.152,10.152,0,0,1-7.41-3.062A10.049,10.049,0,0,1,0-57.975a10.184,10.184,0,0,1,3.063-7.438A10.081,10.081,0,0,1,10.5-68.5Zm0,16.789a.9.9,0,0,0,.656-.273.9.9,0,0,0,.273-.656v-4.43h4.43a.9.9,0,0,0,.656-.273A.9.9,0,0,0,16.789-58a.9.9,0,0,0-.274-.656.9.9,0,0,0-.656-.273H11.43v-4.43a.9.9,0,0,0-.273-.656.9.9,0,0,0-.656-.273.9.9,0,0,0-.656.273.9.9,0,0,0-.273.656v4.43H5.141a.9.9,0,0,0-.656.273A.9.9,0,0,0,4.211-58a.9.9,0,0,0,.273.656.9.9,0,0,0,.656.273H9.57v4.43a.971.971,0,0,0,.109.438.878.878,0,0,0,.328.356A.91.91,0,0,0,10.5-51.714Z"
              transform="translate(0 68.503)"
              fill="#009643"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default QuantityInput;
