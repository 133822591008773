import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import translationEN from "./translations/en.json";
import translationFR from "./translations/fr.json";

const languageDetector = new LanguageDetector();

const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
};

i18n
  .use(languageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: "fr",
    detection: {
      order: ["querystring", "localStorage"],
      lookupQuerystring: "language",
      caches: ["cookies", "localStorage"],
    },

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
