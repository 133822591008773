import { postMsgToParent } from "../postMsgToParent";
import { ga } from "./ga";
import nextuser from "./nextuser";

interface trackProps {
  action: string;
  label?: string;
  value?: number;
  nonInteraction?: boolean;
  nuEventName: string;
}

export const trackEvents = ({
  action,
  label,
  nonInteraction = true,
  value,
  nuEventName,
}: trackProps) => {
  const category = "nu_wiser_configurator";

  const postMessageObj = {
    category,
    action,
    label,
    value,
    nonInteraction,
    nuEventName,
  };

  nextuser(category, nuEventName);

  const gaTrackObj = {
    gaCategory: category,
    gaAction: action,
    gaLabel: label,
    gaNonInteraction: nonInteraction,
    gaValue: value,
  };

  ga(gaTrackObj);

  postMsgToParent("trackEvent", postMessageObj);
};
