import { useTranslation } from 'react-i18next';
import QuantityInput from '../QuantityInput';
import './styles.scss';

interface RoomItemProps {
  title: string;
  image: string;
  roomKey: string;
  onIncrease(key: string): void;
  onDecrease(key: string): void;
  quantity: number;
}

const RoomItem = ({
  title,
  image,
  roomKey,
  onIncrease,
  onDecrease,
  quantity,
}: RoomItemProps) => {
  const [t] = useTranslation();

  return (
    <div className="RoomModal__RoomItem">
      <div className="RoomModal__RoomItem__Image">
        <img src={image} alt={title} />
      </div>
      <div className="RoomModal__RoomItem__Content">
        <div className="RoomModal__RoomItem__Content__Title">{t(title)}</div>
        <div className="RoomModal__RoomItem__Content__Quantity">
          <QuantityInput
            roomKey={roomKey}
            onIncrease={onIncrease}
            onDecrease={onDecrease}
            quantity={quantity}
          />
        </div>
      </div>
    </div>
  );
};

export default RoomItem;
