import { useTranslation } from 'react-i18next';

import './styles.scss';

interface IRecommendedProductProps {
  title: string;
  reference: string;
  imageUrl: string;
  description: string;
  onClick: () => void;
  selected?: boolean;
}

const RecommendedProduct = ({
  title,
  reference,
  imageUrl,
  description,
  selected,
  onClick,
}: IRecommendedProductProps) => {
  const [t] = useTranslation();

  return (
    <div className="RecommendedProduct">
      <div className="RecommendedProduct__Image">
        <img alt={title} src={imageUrl} />
      </div>
      <div className="RecommendedProduct__Title">{title}</div>
      <div className="RecommendedProduct__Reference">{reference || ''}</div>
      <div className="RecommendedProduct__Description">{description || ''}</div>
      <div
        className={`RecommendedProduct__Button ${
          selected ? 'RecommendedProduct__Button--Selected' : ''
        }`}
      >
        <button type="button" onClick={onClick}>
          {!selected ? t('bom.add') : t('bom.added')}
        </button>
      </div>
    </div>
  );
};

export default RecommendedProduct;
