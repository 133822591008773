import React, { FC, useEffect } from "react";
import { QuestionDefinition } from "../../../../../../shared/types";
import { gaActions } from "../../../../../../utils/constants";
import { trackEvents } from "../../../../../../utils/tracking/tracking";
import "./styles.scss";

interface Props {
  step: number;
  activeDeviceType: string;
  answer: string;
  handleAnswer: (option: string) => void;
  options: string[];
  definition: QuestionDefinition;
  isDetailsActive: boolean;
}

const handleGAEvent = function (activeDeviceType: string) {
  if (activeDeviceType === "Heat") {
    activeDeviceType = "Heating";
  }
  trackEvents({
    action: `click_${activeDeviceType}`,
    label: "step_complete",
    nonInteraction: false,
    nuEventName: `click_${activeDeviceType}_step_complete`,
  });
};

const TileSelect: FC<Props> = ({
  step,
  activeDeviceType,
  answer,
  handleAnswer,
  options,
  definition,
  isDetailsActive,
}) => {
  useEffect(() => {
    trackEvents({
      action: `${gaActions.display}_${activeDeviceType}`,
      label: "step_display",
      nuEventName: `${gaActions.display}_${activeDeviceType}_step_display`,
    });
  }, []);
  return (
    <div className={`TileSelect TileSelect--${definition.tile_type}`}>
      {options.map((option) => {
        const isAnswered = option === answer;
        return (
          <div
            key={`TileSelect__Option--${option}`}
            className={`TileSelect__Option ${
              isAnswered ? "TileSelect__Option--Active" : ""
            }`}
            onClick={() => {
              handleAnswer(option);
              handleGAEvent(activeDeviceType);
            }}
          >
            <img alt={option} src={definition.tile_image[option]} />
            <span>{option}</span>
          </div>
        );
      })}
    </div>
  );
};

export default TileSelect;
