import React, { FC, useEffect } from "react";
import { gaActions } from "../../../../../../utils/constants";
import { trackEvents } from "../../../../../../utils/tracking/tracking";
import { QuestionDefinition } from "../../../../../../shared/types";
import "./styles.scss";

interface Props {
  step: number;
  activeDeviceType: string;
  answer: string;
  handleAnswer: (option: string) => void;
  options: string[];
  definition: QuestionDefinition;
  isDetailsActive: boolean;
}

const handleGAEvent = function (activeDeviceType: string) {
  if (activeDeviceType === "Heat") {
    activeDeviceType = "Heating";
  }
  trackEvents({
    action: `click_${activeDeviceType}`,
    label: "step_complete",
    nonInteraction: false,
    nuEventName: `click_${activeDeviceType}_step_complete`,
  });
};

const IconSelect: FC<Props> = ({
  step,
  activeDeviceType,
  answer,
  handleAnswer,
  options,
  definition,
  isDetailsActive,
}) => {
  useEffect(() => {
    trackEvents({
      action: `${gaActions.display}_${activeDeviceType}`,
      label: "step_display",
      nuEventName: `${gaActions.display}_${activeDeviceType}_step_display`,
    });
  }, []);

  const handleOptionClick = (option: string) => () => {
    handleAnswer(option);
    handleGAEvent(activeDeviceType);
  };

  return (
    <div className="IconSelect">
      {options.map((option) => {
        return (
          <div
            key={`IconSelect__Option__${option}`}
            className={`IconSelect__Option ${
              option === answer ? "IconSelect__Option--Active" : ""
            } ${isDetailsActive ? "IconSelect__Option--Flipped" : ""}
            `}
            onClick={handleOptionClick(option)}
          >
            <div className="IconSelect__Option__Front">
              <img alt={option} src={definition?.icons[option]} />
              <span>{option}</span>
            </div>
            <div className="IconSelect__Option__Back">
              <span>{definition?.question_details_text[option] || "N/A"}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default IconSelect;
